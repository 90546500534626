<template>
  <div class="wishing-well-details-container">
    <!--标题 -->
    <div class="wishing-well-details-header row-center">
      <p :style="wishingwellInfo.name.length > 8 ? 'top: 8vw;' : ''">{{ wishingwellInfo.name }}</p>
      <div
        @click="getReword"
        class="wishing-well-details-reword"
      >
      </div>
      <div
        @click="getRecord"
        class="wishing-well-details-record"
      >
      </div>
    </div>
    <!-- 开奖进度 -->
    <div class="wishing-well-plan col-center">
      <!-- 中奖状态 -->
      <div class="wishing-well-plan-title row-center">{{ wishingwellInfo.status == 1 || wishingwellInfo.status == 4 ? '开奖时间' : wishingwellInfo.status == 2 || wishingwellInfo.status == 3 ? '已开奖' : '开始时间'}}</div>
      <div
        class="wishing-well-plan-time row-center"
        v-if="wishingwellInfo.status == 1 || wishingwellInfo.status == 4"
      >
        <div
          style="margin-right: 8px;"
          class="dot"
        ></div>
        {{ wishingwellInfo.drawTime }}
        <div
          style="margin-left: 8px;"
          class="dot"
        ></div>
      </div>
      <div
        class="wishing-well-plan-time row-center"
        v-if="wishingwellInfo.status == 2 || wishingwellInfo.status == 3"
      >

        <div
          style="margin-right: 8px;"
          class="dot"
        ></div>
        {{ wishingwellInfo.status == 3 ? '本场未达目标，未产生幸运用户' : wishingwellInfo.phone + '  中奖啦' }}
        <div
          style="margin-left: 8px;"
          class="dot"
        ></div>
      </div>
      <div
        class="wishing-well-plan-time row-center"
        v-if="wishingwellInfo.status ==0"
      >
        <div
          style="margin-right: 8px;"
          class="dot"
        ></div>
        {{ wishingwellInfo.startTime }}
        <div
          style="margin-left: 8px;"
          class="dot"
        ></div>
      </div>
      <!-- 奖品详情 -->
      <div
        @click="getRewordDetails()"
        class="wishing-well-plan-reword-bg"
      >
        <div
          @click="getRewordDetails()"
          class="wishing-well-plan-button"
        ></div>
      </div>
      <div class="wishing-well-plan-title row-center">最新进度</div>
      <div class="progress-bg">
        <div
          :style="'height: 4vw;width:'+percentage+'%;'"
          class="progress-active"
        ></div>
        <img
          class="red-packet"
          :style="'position: absolute;top: -8px;left:'+percentage+'%;'"
          src="../../assets/icon/christmas/red-packet.png"
        />
        <p class="progress-tip1">0</p>
        <p class="progress-tip2">{{ wishingwellInfo.targetLuckyNum }}</p>
        <p
          :style="'color: #53544f; font-size: 14px; position: absolute; top: 5vw;left:'+percentage+'%;margin-left:' + leftWeight +';'"
          class="tacket-tip"
        >{{ wishingwellInfo.currentLuckyNum ? wishingwellInfo.currentLuckyNum : 0 }}</p>
      </div>
    </div>
    <!-- 用户投入的幸运值 -->
    <div class="user-wishingwell-content col-center">
      <div class="user-wishingwell-content-title row-center">
        我投入的幸运值
      </div>
      <div class="user-wishingwell-luckyNum row-center">
        <p>{{wishingwellUserInfo.luckyNum ? wishingwellUserInfo.luckyNum : 0}}</p>
      </div>
      <!-- 开始时间 -->
      <div
        v-if="wishingwellInfo.status == 0"
        class="user-wishingwell-button4 row-center"
      >
        <p style="margin-bottom: 2vw;">{{ wishingwellInfo.startTime + '开始' }}</p>
      </div>
      <!-- 到时间未开奖 -->
      <div
        v-else-if="wishingwellInfo.status == 4"
        class="user-wishingwell-button4 row-center"
      >
        <p style="margin-bottom: 2vw;"> 本场已结束</p>
      </div>
      <!-- 已开奖 -->
      <div
        v-else-if="wishingwellInfo.status == 2 || wishingwellInfo.status == 3"
        class="user-wishingwell-button1"
      ></div>
      <!-- 已达目标，静待开奖 -->
      <div
        v-else-if="parseInt(wishingwellInfo.currentLuckyNum) >= parseInt(wishingwellInfo.targetLuckyNum) &&  wishingwellInfo.status == 1"
        class="user-wishingwell-button2"
      ></div>
      <!-- 立即参加 -->
      <div
        v-else
        @click="getWishingwellHashSku"
        class="user-wishingwell-button3"
      ></div>
      <p class="user-wishingwell-tip">{{ wishingwellInfo.isLimitLuckyNum == 1 ? '本场每人最多投入' +  wishingwellInfo.maxLuckyNum  + '幸运值' : '本场每人最多投入幸运值不限'}}</p>
    </div>
    <!-- 藏品与幸运值 -->
    <div class="collection-luck-content col-center">
      <div class="user-wishingwell-content-title row-center">
        藏品与幸运值
      </div>
      <!-- <div class="collection-luck-content-title"></div> -->
      <div
        @click="getWishingwellSku"
        class="go-details row-center"
      >
        查看更多
        <img src="../../assets/icon/christmas/next.png" />
      </div>
      <div
        v-for="(i,j) in wishingwellInfo.skuList"
        :key="j"
        class="collection-luck-item-bg row-center"
      >
        <div class="collection-luck-item row-start">
          <div class="collection-luck-item-img row-center">
            <img :src="i.skuImg" />
          </div>

          <div class="col-start">
            <p class="collection-luck-item-name">{{ i.skuName }}</p>
            <p class="collection-luck-item-lucky">{{ i.luckyNum + '幸运值/份'}}</p>
          </div>
        </div>

      </div>
    </div>
    <!-- 规则说明 -->
    <div style="position: relative;">
      <div
        class="lottery-rule"
        v-html="wishingwellInfo.rules"
      >

      </div>
      <div class="lottery-rule-title row-center">
        规则说明
      </div>
    </div>

    <div class="gashapon-agreement col-center">
      <img
        class="gashapon-logo"
        :src="require('../../assets/icon/aboutUs/logo.png')"
      />
      <p class="gashapon-logo-title">Meta彼岸</p>
      <p class="gashapon-agreement-title">智链2.0提供区块链技术支持</p>
      <p class="gashapon-agreement-tip">Copyright © GenimousChain Ltd.All rights reserved</p>
    </div>
    <el-dialog
      title="账户未登录"
      :visible.sync="loginVisible"
      @confirm="goLogin"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="loginVisible = false">取 消</el-button>
        <div
          @click="goLogin()"
          class="confirm-button row-center"
        >去登录</div>
      </span>
    </el-dialog>
    <el-dialog
      title="账户未实名认证"
      :visible.sync="authVisible"
      @confirm="$router.push('/certification')"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="authVisible = false">取 消</el-button>
        <div
          @click="$router.push('/certification')"
          class="confirm-button row-center"
        >去认证</div>
      </span>
    </el-dialog>
    <!-- 用户持有藏品 -->
    <van-popup
      closeable
      v-model="collectionUserVisi"
      position="bottom"
      style="height: 500px;background: #FFFAF4;"
      :close-on-click-overlay="false"
    >
      <p class="donation-popup-title1">参与活动</p>
      <div style="height: 380px;overflow-y:auto;">
        <div
          class="user-collection-content row-between"
          v-for="(i,j) in userHashSku"
          :key="j"
        >
          <div class="user-collection-item row-start">
            <div class="collection-exchange-item-img-bg row-center">
              <img :src="i.skuImg" />
            </div>
            <div class="col-start">
              <p class="collection-exchange-item-skuName">{{ i.skuName }}</p>
              <p class="collection-exchange-item-num">{{ i.luckyNum + '幸运值/份'}}</p>
              <p class="collection-exchange-item-num">{{'剩余：' + i.skuNum + '份'}}</p>
            </div>
          </div>
          <div class="luckNum-add-minus row-center">
            <img
              @click="userNumMinus(i)"
              :style="i.userNum==0 ? 'opacity: 0.5;': ''"
              src="../../assets/icon/christmas/num-minus.png"
            />
            <p>{{ i.userNum }}</p>
            <img
              @click="userNumAdd(i)"
              :style="i.userNum==i.skuNum ? 'opacity: 0.5;': ''"
              src="../../assets/icon/christmas/num-add.png"
            />
          </div>
        </div>
      </div>
      <div class="user-collection-button-content row-center">
        <div class="user-luck-num col-center">
          <p class="luck-num">{{ userLuckAllNum }}</p>
          <p class="luck-num-tip">幸运值</p>
        </div>
        <div
          class="user-collection-button1 row-center"
          @click="submitUserCollection()"
        >
          确定
        </div>

      </div>
    </van-popup>
    <!-- 参与成功 -->
    <div
      v-if="lottertShade"
      class="lottery-shade-bg"
    >
    </div>
    <div
      v-if="lottertShade"
      @click="closeLotteryExchange()"
      class="lottery-content"
    >
      <div class="lottery-content-bg col-center">
        <img src="../../assets/icon/gashapon/right.png" />
        <p class="lottery-content-title">参与成功</p>
        <!-- <p class="lottery-content-tip">本次投入{{userLuckAllNum}}个幸运值</p> -->
      </div>
    </div>
    <!-- 奖品弹窗 -->
    <div
      v-if="rewordShade"
      class="reword-shade-bg"
    >
    </div>
    <div
      v-if="rewordShade"
      @click="closeRewordShade()"
      class="reword-content-bg"
    >
      <div class="reword-content-detail">
        <div
          v-for="(item,index) in rewordList"
          :key="index"
          class="col-center"
        >
          <div class="lottery-open-list-content-item1 row-center">
            <img
              class="lottery-open-list-item-lottery-img"
              :src="item.img"
            />
          </div>

          <p>x{{ item.num }}</p>
        </div>
      </div>
      <div
        @click="rewordShade=false"
        class="close-button"
      ></div>
    </div>
    <!-- 获奖弹窗 -->
    <div
      v-if="userRewordShade"
      class="reword-shade-bg"
    >
    </div>
    <div
      v-if="userRewordShade"
      @click="closeRewordShade()"
      class="user-reword-content-bg"
    >
      <div class="reword-content-detail1">
        <div
          v-for="(item,index) in rewordList"
          :key="index"
          class="col-center"
        >
          <div class="lottery-open-list-content-item1 row-center">
            <img
              class="lottery-open-list-item-lottery-img"
              :src="item.img"
            />
          </div>

          <p>x{{ item.num }}</p>
        </div>
      </div>
      <div
        @click="userRewordShade=false"
        class="user-close-button"
      ></div>
    </div>
    <!-- 校验资产密码 -->
    <van-popup
      closeable
      v-model="show"
      position="bottom"
      style="height: 411px;background: rgba(21, 21, 21, 0.85);"
      :close-on-click-overlay="false"
    >
      <div v-if="payStep == 1">
        <p class="donation-popup-title">安全验证</p>
        <!-- 密码输入框 -->
        <p class="donation-popup-tip">验证资产密码</p>
        <van-password-input
          :value="pwdValue"
          :gutter="8"
          :focused="showKeyboard"
          @focus="showKeyboard = true"
        />
        <!-- 数字键盘 -->
        <van-number-keyboard
          v-model="pwdValue"
          :show="showKeyboard"
          @blur="show = false"
        />
      </div>
    </van-popup>
    <van-popup
      closeable
      v-model="collectionAllVisi"
      position="bottom"
      style="height: 500px;background: #FFFAF4;"
      :close-on-click-overlay="false"
    >
      <p class="donation-popup-title1">藏品与幸运值</p>
      <div style="height: 380px;overflow-y:auto;">
        <div
          class="user-collection-content row-start"
          v-for="(i,j) in collectionAll"
          :key="j"
        >
          <div class="collection-exchange-item-img-bg row-center">
            <img :src="i.skuImg" />
          </div>

          <div class="col-start">
            <p class="user-collection-item-skuName">{{ i.skuName }}</p>
            <p class="user-collection-item-luckyNum">{{ i.luckyNum + '幸运值/份'}}</p>
          </div>
        </div>
      </div>
      <div class="user-collection-button-content row-center">
        <div
          class="user-collection-button row-center"
          @click="collectionAllVisi=false"
        >
          确定
        </div>
      </div>

    </van-popup>
  </div>
</template>
<script>
import Vue from 'vue'
import md5 from 'js-md5';
import Cookies from 'js-cookie'
import api from '../../api/index-client'
import { Button,Dialog } from 'element-ui';
import { Progress,Field,PasswordInput,NumberKeyboard,Popup,Stepper } from 'vant';
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Progress);
Vue.use(Field);
Vue.use(Stepper);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Popup);
export default {
  data () {
    return {
      leftWeight: '0vw',
      userRewordShade: false,
      rewordShade: false,
      lottertShade: false,
      userLuckAllNum: 0,
      collectionAllVisi: false,
      collectionUserVisi: false,
      value: '',
      payStep: 1,
      showKeyboard: true,
      pwdValue: '',
      show: false,
      userHashSku: [],
      loginVisible: false,
      authVisible: false,
      isAuth: false,
      wishingwellInfo: {
        name: '',
        status: null,
        phone: '',
        startTime: '',
        drawTime: '',
        skuList: [],
        isLimitLuckyNum: 0
      },
      collectionAll: [],
      rewordList: [],
      loginStatus: false,
      percentage: 0,
      wishingwellUserInfo: {}
    }
  },
  watch: {
    pwdValue () {
      if (this.pwdValue.length === 6) {
        this.testOldPwd()
      }
    },
  },
  mounted () {
    this.getWishingwellDetail()
    this.loginStatus = Cookies.get('Login') ? true : false
    if (Cookies.get('Login')) {
      this.getMyInfo()
    }
  },
  methods: {
    // 校验资产密码
    testOldPwd () {
      api
        .post('user/verifyAssetsPassword',{ assetsPassword: md5(this.pwdValue) })
        .then(result => {
          if (result.data.success) {
            this.wishingwellPutAssets()
          } else {
            this.pwdValue = ''
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 关闭奖品详情弹窗
    closeRewordShade () {
      this.rewordShade = false
    },
    wishingwellPutAssets () {
      let param = {
        code: this.$route.query.code,
        skuList: [],
        pwd: md5(this.pwdValue)
      }
      this.userHashSku.filter(i => i.userNum).map(j => {
        param.skuList.push({
          skuNo: j.skuNo,
          skuNum: j.userNum
        })
      })
      api
        .post('wishingwell/user/putAssets',param)
        .then(result => {
          if (result.data.success) {
            this.show = false
            this.lottertShade = true
          } else {
            this.pwdValue = ''
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    closeLotteryExchange () {
      this.lottertShade = false
      this.getWishingwellDetail()
      this.getMyInfo()
    },
    // 计算使用幸运值
    calcAllUserLuckNum () {
      let e = 0
      this.userHashSku.filter(i => i.userNum).map(j => {
        e += parseInt(j.luckyNum) * parseInt(j.userNum)
      })
      this.userLuckAllNum = e
      this.$forceUpdate()
    },
    // 获取个人基本信息
    getMyInfo () {
      api
        .get('userinfo')
        .then(result => {
          if (result.data.success) {
            this.isAuth = result.data.data.hasRealname
            if (this.isAuth) {
              this.getWishingwellUser()
            }
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 获取用户信息
    getWishingwellUser () {
      api
        .get('wishingwell/user?code=' + this.$route.query.code)
        .then(result => {
          if (result.data.success) {
            this.wishingwellUserInfo = result.data.data
            this.$forceUpdate()
            if (result.data.data.isLuckyGuy == 1 && result.data.data.isNotify == 0) {
              this.getUserReword()
            }
            console.log(this.percentage);
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 登录
    goLogin () {
      localStorage.setItem('loginToWishing','wishingWellDetails?code=' + this.$route.query.code)
      this.$router.push('/login')
    },
    getRecord () {
      if (!Cookies.get('Login')) {
        this.loginVisible = true
      } else if (!this.isAuth) {
        this.authVisible = true
      } else {
        this.$router.push('/recordList?id=' + this.wishingwellInfo.id)
      }

    },
    getReword () {
      if (!Cookies.get('Login')) {
        this.loginVisible = true
      } else if (!this.isAuth) {
        this.authVisible = true
      } else {
        this.$router.push('/rewordList?id=' + this.wishingwellInfo.id)
      }

    },
    // 使用数量加减
    userNumMinus (e) {
      if (e.userNum > 0) {
        e.userNum -= 1
        this.calcAllUserLuckNum()
        this.$forceUpdate()
      }
    },
    userNumAdd (e) {
      if (e.userNum < e.skuNum) {
        e.userNum += 1
        this.calcAllUserLuckNum()
        this.$forceUpdate()
      }
    },
    // 提交数据前密码校验
    submitUserCollection () {
      if (this.userHashSku.filter(i => i.userNum).length) {
        this.pwdValue = ''
        this.show = true
        this.collectionUserVisi = false
      } else {
        this.$toast({
          message: '请选择藏品',
          icon: require('../../assets/icon/toast-error.png'),
        });
      }

    },
    // 获取更多藏品积分信息
    getWishingwellSku () {
      api
        .get('wishingwell/sku?id=' + this.wishingwellInfo.id)
        .then(result => {
          if (result.data.success) {
            this.collectionAll = result.data.data
            this.collectionAllVisi = true
          } else {
            this.$store.commit('HIDE_APPLOADING')
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 获取活动详情
    getWishingwellDetail () {
      api
        .get('wishingwell/detail?code=' + this.$route.query.code)
        .then(result => {
          if (result.data.success) {
            this.wishingwellInfo = result.data.data
            this.wishingwellInfo.name = this.wishingwellInfo.name.length > 16 ? this.wishingwellInfo.name.substring(0,16) : this.wishingwellInfo.name
            this.leftWeight = '-' + String(this.wishingwellInfo.currentLuckyNum).length + 'vw'
            this.percentage = (parseInt(parseInt(this.wishingwellInfo.currentLuckyNum) * 100) / parseInt(this.wishingwellInfo.targetLuckyNum)) >= 100 ? 96 : (parseInt(parseInt(this.wishingwellInfo.currentLuckyNum) * 100) / parseInt(this.wishingwellInfo.targetLuckyNum))
            this.$forceUpdate()
            this.$store.commit('HIDE_APPLOADING')
          } else {
            this.$store.commit('HIDE_APPLOADING')
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 获取用户持有藏品
    getWishingwellHashSku () {
      if (!Cookies.get('Login')) {
        this.loginVisible = true
      } else if (!this.isAuth) {
        this.authVisible = true
      } else {
        if (parseInt(this.wishingwellInfo.currentLuckyNum) < parseInt(this.wishingwellInfo.targetLuckyNum) && this.wishingwellInfo.status == 1) {
          api
            .get('wishingwell/user/sku?id=' + this.wishingwellInfo.id)
            .then(result => {
              if (result.data.success) {
                this.userHashSku = []
                result.data.data.map((i,j) => {
                  this.userHashSku.push(i)
                  this.userHashSku[j].userNum = 0
                })
                this.collectionUserVisi = true
              } else {
                this.$toast({
                  message: result.data.msg,
                  icon: require('../../assets/icon/toast-error.png'),
                });
              }
            })
        }
      }
    },
    getUserReword () {
      api
        .get('wishingwell/reward?id=' + this.wishingwellInfo.id)
        .then(result => {
          if (result.data.success) {
            console.log(result.data.data);
            this.rewordList = result.data.data
            this.userRewordShade = true
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 获取奖品信息
    getRewordDetails () {
      api
        .get('wishingwell/reward?id=' + this.wishingwellInfo.id)
        .then(result => {
          if (result.data.success) {
            console.log(result.data.data);
            this.rewordList = result.data.data
            this.rewordShade = true
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    }
  },
}
</script>
<style lang="less" scoped>
.wishing-well-details-container {
  width: 100vw;
  min-height: 100vh;
  overflow-y: auto;
  background: #fe525a;
  .wishing-well-details-header {
    position: relative;
    width: 100vw;
    height: 70vw;
    background-image: url('../../assets/icon/christmas/wishing-well-details-header.png');
    background-repeat: repeat;
    background-attachment: scroll;
    background-position: 0% 64%;
    background-size: 100% auto;
    p {
      width: 80vw;
      text-align: center;
      position: absolute;
      left: 10vw;
      font-size: 10vw;
      background-image: -webkit-linear-gradient(top, #fffdfa, #ffeccc, #feeac5);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      top: 14vw;
      font-family: 'ZiZhiQuXiMaiTi';
    }
    .wishing-well-details-reword {
      position: absolute;
      width: 100px;
      height: 40px;
      top: 12px;
      left: 0px;
    }
    .wishing-well-details-record {
      position: absolute;
      width: 100px;
      height: 40px;
      top: 12px;
      right: 0px;
    }
  }
  .wishing-well-plan {
    width: 90vw;
    margin-left: 5vw;
    height: 98vw;
    background-image: url('../../assets/icon/christmas/wishing-well-plan-bg.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: contain;
    .wishing-well-plan-title {
      color: #ff6851;
      font-size: 18px;
      font-weight: 500;
      width: 45vw;
      height: 4.4vw;
      background-image: url('../../assets/icon/christmas/title-style.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: 0% 0%;
      background-size: contain;
    }
    .wishing-well-plan-time {
      width: auto;
      height: 25px;
      padding: 0 20px;
      background: #fe6466;
      border-radius: 2rem;
      color: #ffffff;
      font-size: 14px;
      margin-top: 10px;
      .dot {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: #ffffff;
      }
    }
    .wishing-well-plan-reword-bg {
      width: 72vw;
      height: 59vw;
      margin-left: 7vw;
      margin-top: -6vw;
      background-image: url('../../assets/icon/christmas/reword-bg.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: 0% 0%;
      background-size: contain;
      position: relative;
      .wishing-well-plan-button {
        width: 24vw;
        height: 6.3vw;
        background-image: url('../../assets/icon/christmas/record-dedtails-button.png');
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: 0% 0%;
        background-size: contain;
        position: absolute;
        left: 20vw;
        bottom: 5vw;
      }
      .wishing-well-plan-button:active {
        opacity: 0.6;
      }
    }
    .progress-bg {
      margin-top: 15px;
      width: 83vw;
      height: 4.4vw;
      margin-bottom: 7vw;
      background-image: url('../../assets/icon/christmas/bar-bg.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: 0% 0%;
      background-size: contain;
      position: relative;
      z-index: 99;
      .progress-active {
        background: #fe5159;
        border-radius: 2rem;
        z-index: 100;
      }
      .red-packet {
        z-index: 101;
        width: 25px;
        height: 29px;
        margin-left: -4%;
      }
      .progress-tip1 {
        color: #53544f;
        font-size: 16px;
        position: absolute;
        top: 9.5vw;
        left: 0;
      }
      .progress-tip2 {
        color: #53544f;
        font-size: 16px;
        position: absolute;
        top: 9.5vw;
        right: 0;
      }
    }
  }
  .user-wishingwell-content {
    margin-top: 20px;
    width: 90vw;
    margin-left: 5vw;
    height: 61.3vw;
    background-image: url('../../assets/icon/christmas/user-wishingwell-bg.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: contain;
    position: relative;
    .user-wishingwell-content-title {
      width: 46vw;
      height: 10vw;
      background-image: url('../../assets/icon/christmas/title-bg.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: 0% 0%;
      background-size: contain;
      position: absolute;
      top: -1vw;
      left: 22vw;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
    }
    .user-wishingwell-luckyNum {
      width: 32.7vw;
      height: 32.37vw;
      background-image: url('../../assets/icon/christmas/wishingwell-bg.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: 0% 0%;
      background-size: contain;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      margin-top: 4vw;
    }
    .user-wishingwell-button1 {
      width: 32.7vw;
      height: 10vw;
      background-image: url('../../assets/icon/christmas/prizes-drawn.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: 0% 0%;
      background-size: contain;
      margin: 2vw 0 1vw 0;
    }
    .user-wishingwell-button2 {
      width: 43vw;
      height: 10vw;
      background-image: url('../../assets/icon/christmas/no-prizes-drawn.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: 0% 0%;
      background-size: contain;
      margin: 2vw 0 1vw 0;
    }
    .user-wishingwell-button3 {
      width: 35vw;
      height: 10vw;
      background-image: url('../../assets/icon/christmas/involved-right.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: 0% 0%;
      background-size: contain;
      margin: 2vw 0 1vw 0;
    }
    .user-wishingwell-button3:active {
      opacity: 0.6;
    }
    .user-wishingwell-button4 {
      width: 43vw;
      height: 10vw;
      background-image: url('../../assets/icon/christmas/prizes-drawn1.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: 0% 0%;
      background-size: contain;
      font-size: 13px;
      font-weight: 500;
      color: #ffffff;
      margin: 2vw 0 1vw 0;
    }
    .user-wishingwell-tip {
      font-size: 13px;
      font-weight: 400;
      color: #fe525a;
    }
  }
  .collection-luck-content {
    width: 90vw;
    margin-left: 5vw;
    margin-top: 20px;
    height: auto;
    background: #fff5e9;
    border-radius: 10px;
    position: relative;
    padding-bottom: 10px;
    padding-top: 12vw;
    .user-wishingwell-content-title {
      width: 46vw;
      height: 10vw;
      background-image: url('../../assets/icon/christmas/title-bg.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: 0% 0%;
      background-size: contain;
      position: absolute;
      top: -1vw;
      left: 22vw;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
    }
    .collection-luck-content-title {
      width: 33.7vw;
      height: 5.5vw;
      background-image: url('../../assets/icon/christmas/collection-lucky.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: 0% 0%;
      background-size: contain;
      margin: 27px 18vw 10px 0;
    }
    .go-details {
      position: absolute;
      top: 2vw;
      right: 5vw;
      font-size: 13px;
      font-weight: 400;
      color: #ed6142;
      img {
        width: 7px;
        height: auto;
      }
    }
    .collection-luck-item-bg {
      width: 81vw;
      height: 100px;
      background: #ffffff;
      border-radius: 10px;
      margin: 5px 0;
      .collection-luck-item {
        width: 80vw;
        height: 95px;
        border-radius: 10px;
        background-image: linear-gradient(#fff6d7, #fdfbed);
        .collection-luck-item-img {
          width: 72px;
          height: 72px;
          border-radius: 10px;
          margin-left: 15px;
          background: #ffe7e0;
          img {
            width: 70px;
            height: 70px;
            border-radius: 10px;
          }
        }
        .collection-luck-item-name {
          font-size: 15px;
          font-weight: 600;
          color: #5d5b51;
          margin-bottom: 17px;
          margin-left: 10px;
        }
        .collection-luck-item-lucky {
          font-size: 14px;
          font-weight: 400;
          color: #5d5b51;
          margin-left: 10px;
        }
      }
    }
  }
  .lottery-rule-title {
    width: 46vw;
    height: 10vw;
    background-image: url('../../assets/icon/christmas/title-bg.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: contain;
    position: absolute;
    top: -1vw;
    left: 28vw;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }
  .lottery-rule {
    width: 82vw;
    margin-left: 5vw;
    margin-top: 20px;
    height: auto;
    background: #fff5e9;
    border-radius: 10px;
    white-space: pre-wrap;
    word-break: break-all;
    padding: 11vw 4vw 10px 4vw;
    font-size: 0.875rem !important;
    font-family: NotoSansHans-Regular, NotoSansHans !important;
    font-weight: 500 !important;
    color: #722e0a !important;
    line-height: 1.5rem !important;
    p {
      font-size: 0.875rem !important;
      font-family: NotoSansHans-Regular, NotoSansHans !important;
      font-weight: 500 !important;
      color: #722e0a !important;
      line-height: 1.5rem !important;
      span {
        font-size: 0.875rem !important;
        font-family: NotoSansHans-Regular, NotoSansHans !important;
        font-weight: 500 !important;
        color: #722e0a !important;
        line-height: 1.5rem !important;
      }
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .gashapon-agreement {
    .gashapon-logo {
      width: 100px;
      height: auto;
      margin-bottom: 5px;
    }
    .gashapon-logo-title {
      font-size: 12px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 17px;
    }
    .gashapon-agreement-title,
    .gashapon-agreement-tip {
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 5px;
    }
  }
}
.van-popup {
  .donation-popup-title {
    margin: 1.625rem 0 1.25rem 0;
    text-align: center;
    font-size: 1.125rem;
    font-family: lantingheiweight;
    font-weight: 700;
    color: #f0f0f0;
  }
  .donation-popup-title1 {
    margin: 15px 0 10px 0;
    text-align: center;
    font-size: 1.125rem;
    font-family: lantingheiweight;
    font-weight: 500;
    color: #000000;
  }
  .user-collection-content {
    width: 90vw;
    height: 21.34vw;
    margin-left: 5vw;
    background: #de8466;
    margin-bottom: 10px;
    border-radius: 10px;
    .collection-exchange-item-img-bg {
      width: 17vw;
      height: 17vw;
      background-image: url('../../assets/icon/gashapon/collection-exchange-item-img-bg.png');
      background-size: 100% auto;
      background-repeat: no-repeat;
      margin-right: 9px;
      margin-left: 3vw;
      img {
        width: 14vw;
        height: 14vw;
      }
    }
    .user-collection-item-skuName {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
    }
    .user-collection-item-luckyNum {
      font-size: 12px;
      font-weight: 500;
      margin-top: 10px;
      color: #ffffff;
    }
  }
  .user-luck-num {
    width: 100px;
    margin-left: 10vw;
    .luck-num {
      font-size: 17px;
      font-weight: 500;
      color: #000000;
    }
    .luck-num-tip {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
    }
  }
  .collection-exchange-item-skuName {
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 5px;
    width: calc(70vw - 100px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
  .collection-exchange-item-num {
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
  }
  .luckNum-add-minus {
    width: 100px;
    img {
      width: 20px;
      height: 20px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      margin: 0 10px;
    }
  }
  .user-collection-button-content {
    width: 100vw;
    height: 70px;
    background: #f8dbd3;
    border-radius: 10px 10px 0 0;
  }
  .user-collection-button1 {
    width: 40vw;
    height: 50px;
    border-radius: 10px;
    background-image: linear-gradient(#ff6800, #fe0103);
    margin: 10px 10vw 0 30vw;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: 500;
    color: #ffffff;
  }
  .user-collection-button {
    width: 40vw;
    height: 50px;
    border-radius: 10px;
    background-image: linear-gradient(#ff6800, #fe0103);
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: 500;
    color: #ffffff;
  }
  .user-collection-button1:active,
  .user-collection-button:active {
    opacity: 0.6;
  }
  .donation-popup-donationPay {
    text-align: center;
    font-size: 2.1875rem;
    font-family: SFUIDisplay-Bold, SFUIDisplay;
    font-weight: bold;
    color: #ffffff;
    line-height: 2.625rem;
    margin-bottom: 2.4375rem;
  }
  .donation-popup-tip {
    margin: 0 0 0.875rem 1rem;
    font-size: 0.875rem;
    font-family: lantingheiweight;
    font-weight: 600;
    color: #666666;
    line-height: 19px;
  }
  .van-password-input__security li {
    background: #565656;
    border-radius: 0.25rem;
  }
  .van-number-keyboard {
    background: rgba(21, 21, 21, 0.85);
  }
  /deep/.van-number-keyboard .van-key {
    background-color: #646465;
    box-shadow: 0px 1px 0px 0px #000000;
    font-family: Helvetica;
    color: #ffffff;
  }
}
.lottery-shade-bg {
  z-index: 1997;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000000;
  opacity: 0.5;
}
.reword-shade-bg {
  z-index: 1997;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000000;
  opacity: 0.5;
}
.reword-content-bg {
  z-index: 1998;
  position: fixed;
  width: 92vw;
  height: 92vw;
  top: 20vw;
  left: 4vw;
  background-image: url('../../assets/icon/christmas/reword-details-bg.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  background-size: contain;
}
.user-reword-content-bg {
  z-index: 1998;
  position: fixed;
  width: 89.6vw;
  height: 137.6vw;
  top: 5vw;
  left: 5.2vw;
  background-image: url('../../assets/icon/christmas/get-reword-tip-bg.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  background-size: contain;
}
.close-button {
  z-index: 1999;
  position: fixed;
  width: 31.6vw;
  height: 12vw;
  top: 93vw;
  left: 34.2vw;
  background-image: url('../../assets/icon/christmas/close-button.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  background-size: contain;
}
.user-close-button {
  z-index: 1999;
  position: fixed;
  width: 31.6vw;
  height: 12vw;
  top: 126vw;
  left: 34.2vw;
}
.user-close-button:active,
.close-button:active {
  opacity: 0.6;
}
.reword-content-detail1 {
  z-index: 1999;
  position: fixed;
  width: 81vw;
  height: 49vw;
  top: 71vw;
  left: 9vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  .lottery-open-list-content-item1 {
    width: 15vw;
    height: 15vw;
    margin-left: 1vw;
    background-image: url('../../assets/icon/gashapon/lottery-open-list-content-item.png');
    background-size: 100% auto;
    background-repeat: no-repeat;

    .lottery-open-list-item-lottery-img {
      width: 13vw;
      height: 13vw;
      border-radius: 10px;
    }
  }
}
.reword-content-detail {
  z-index: 1999;
  position: fixed;
  width: 81vw;
  height: 49vw;
  top: 38vw;
  left: 9vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  .lottery-open-list-content-item1 {
    width: 15vw;
    height: 15vw;
    margin-left: 1vw;
    background-image: url('../../assets/icon/gashapon/lottery-open-list-content-item.png');
    background-size: 100% auto;
    background-repeat: no-repeat;

    .lottery-open-list-item-lottery-img {
      width: 13vw;
      height: 13vw;
      border-radius: 10px;
    }
  }
}
.lottery-content {
  z-index: 1998;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  .lottery-content-bg {
    width: 80vw;
    height: 80vw;
    margin: 30vw 10vw 0 10vw;
    background: #f9dcd4;
    border-radius: 10px;
    img {
      width: 30%;
      height: 30%;
    }
    .lottery-content-title {
      font-size: 22px;
      font-weight: bold;
      color: #3d3d3d;
      margin: 60px 0 20px 0;
    }
    .lottery-content-tip {
      font-size: 16px;
      font-weight: 800;
      color: #3d3d3d;
    }
  }
}
</style>